<template>
  <b-card
    no-body
  >
    <div class="card-header">
      <b-card-title>
        {{ title }}
      </b-card-title>
      <feather-icon
        class="float-right cursor-pointer"
        size="21"
        icon="XIcon"
        @click="$emit('closeForm')"
      />
    </div>

    <template>
      <b-card-body>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-row>
                  <!-- Coupon Type -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Coupon Type</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="CouponType"
                      >
                        <v-select
                          v-model="couponCatalogCopy.coupon_type_id"
                          label="name"
                          :state="errors.length > 0 ? false:null"
                          :options="couponTypes"
                          :reduce="couponType => couponType.id"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Name -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Name</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Name"
                      >
                        <b-form-input
                          v-model="couponCatalogCopy.name"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Description -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Description</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Description"
                      >
                        <b-form-input
                          v-model="couponCatalogCopy.description"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Description"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--  Config -->
                  <b-col cols="12">
                    <b-form-group>
                      <label>Config</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Config"
                      >
                        <vue-json-editor
                          v-model="couponCatalogCopy.config"
                          name="Config"
                          :show-btns="false"
                          :expanded-on-start="true"
                          :mode="'code'"
                          style="height: '300px'; width: 100%"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- submit button -->
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      type="submit"
                      class="mr-1"
                      @click.prevent="validationForm"
                    >
                      Save
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </template>
  </b-card>
</template>

<script>

import vueJsonEditor from 'vue-json-editor'

import { mapState } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardTitle, BCardBody, BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  required,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardTitle,
    BCardBody,
    BFormInput,
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vueJsonEditor,
  },
  props: {
    action: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      title: '',
      required,
    }
  },
  computed: {
    ...mapState('couponCatalog', {
      couponCatalog: 'couponCatalog',
    }),
    ...mapState('couponType', {
      couponTypes: 'couponTypes',
    }),
    couponCatalogCopy() {
      return { ...this.couponCatalog }
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('actionSave', this.couponCatalogCopy)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
