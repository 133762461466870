var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Coupon Catalogs"}},[_c('div',{staticClass:"float-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-5",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('addCouponCatalog')}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Add new")])],1)],1),_c('table-search',{on:{"changeSearchTerm":_vm.changeSearchTerm}}),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm },"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"pagination-options":{
      enabled: true,
      perPage:_vm.pageLength
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('table-actions',{on:{"actionEdit":function($event){return _vm.$emit('editCouponCatalog', props.row)},"actionDelete":function($event){return _vm.$emit('deleteCouponCatalog', props.row)}}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('table-pagination',{attrs:{"props":props,"page-length":_vm.pageLength}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }