var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"card-header"},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('feather-icon',{staticClass:"float-right cursor-pointer",attrs:{"size":"21","icon":"XIcon"},on:{"click":function($event){return _vm.$emit('closeForm')}}})],1),[_c('b-card-body',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Coupon Type")]),_c('validation-provider',{attrs:{"rules":"required","name":"CouponType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","state":errors.length > 0 ? false:null,"options":_vm.couponTypes,"reduce":function (couponType) { return couponType.id; }},model:{value:(_vm.couponCatalogCopy.coupon_type_id),callback:function ($$v) {_vm.$set(_vm.couponCatalogCopy, "coupon_type_id", $$v)},expression:"couponCatalogCopy.coupon_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Name")]),_c('validation-provider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Name"},model:{value:(_vm.couponCatalogCopy.name),callback:function ($$v) {_vm.$set(_vm.couponCatalogCopy, "name", $$v)},expression:"couponCatalogCopy.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Description")]),_c('validation-provider',{attrs:{"rules":"required","name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Description"},model:{value:(_vm.couponCatalogCopy.description),callback:function ($$v) {_vm.$set(_vm.couponCatalogCopy, "description", $$v)},expression:"couponCatalogCopy.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Config")]),_c('validation-provider',{attrs:{"rules":"required","name":"Config"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-json-editor',{staticStyle:{"height":"'300px'","width":"100%"},attrs:{"name":"Config","show-btns":false,"expanded-on-start":true,"mode":'code'},model:{value:(_vm.couponCatalogCopy.config),callback:function ($$v) {_vm.$set(_vm.couponCatalogCopy, "config", $$v)},expression:"couponCatalogCopy.config"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Save ")]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }