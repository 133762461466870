<template>
  <div>
    <div
      v-if="isLoadingComplete"
      class="d-flex align-items-center"
    >
      <strong>Loading...</strong>
      <b-spinner class="ml-auto" />
    </div>
    <table-coupon-catalog
      v-show="!showForm"
      :coupon-catalogs="tableData"
      @addCouponCatalog="addCouponCatalog($event)"
      @editCouponCatalog="editCouponCatalog($event)"
      @deleteCouponCatalog="confirmDeleteCouponCatalog($event)"
    />
    <form-coupon-catalog
      v-show="showForm"
      :action="action"
      @closeForm="closeForm"
      @actionSave="actionSave"
    />
  </div>
</template>

<script>

import { BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapActions, mapMutations, mapState } from 'vuex'

import TableCouponCatalog from '@/components/catalogs/coupon-catalog/TableCouponCatalog.vue'
import FormCouponCatalog from '@/components/catalogs/coupon-catalog/FormCouponCatalog.vue'

export default {
  components: {
    BSpinner,
    TableCouponCatalog,
    FormCouponCatalog,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      showForm: false,
      action: '',
      searchTerm: '',
    }
  },

  computed: {
    ...mapState('couponCatalog', ['couponCatalogs', 'isLoading']),
    tableData() {
      return this.couponCatalogs
    },
    isLoadingComplete() {
      return this.isLoading
    },
  },

  mounted() {
    this.searchCouponCatalog()
    this.searchCouponType()
  },

  methods: {
    ...mapActions('couponCatalog',
      ['searchCouponCatalog', 'createCouponCatalog', 'deleteCouponCatalog', 'updateCouponCatalog', 'restoreCouponCatalog']),
    ...mapActions('couponType',
      ['searchCouponType']),
    ...mapMutations('couponCatalog',
      { setCouponCatalog: 'setCouponCatalog' }),
    addCouponCatalog() {
      this.showForm = true
      this.restoreCouponCatalog()
      this.action = 'Create'
    },
    editCouponCatalog(couponCatalog) {
      this.setCouponCatalog(couponCatalog)
      this.showForm = true
      this.action = 'Edit'
    },
    closeForm() {
      this.showForm = false
    },
    actionSave(couponCatalog) {
      if (this.action === 'Edit') {
        this.updateCouponCatalog(couponCatalog)
      }
      if (this.action === 'Create') {
        this.createCouponCatalog(couponCatalog)
      }
      this.showForm = false
    },
    async confirmDeleteCouponCatalog(couponCatalog) {
      const response = await this.$swal({
        title: `Are you sure to delete the coupon catalog ${couponCatalog.name}.?`,
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn Are you sure',
        },
      })
      if (response && response.value) {
        this.deleteCouponCatalog(couponCatalog)
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
